import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CartComponentItem from './CartItemComponent';
import axios from 'axios';
import CartItemSession from './CartItemSession';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import authHeader from './auth-header';
import { FaCheckCircle } from 'react-icons/fa';
import InputMask from 'react-input-mask'; 

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [contactInfo, setContactInfo] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userId, setUserId] = useState(0);
  const [checkout, setCheckout] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [payment, setPayment] = useState(false);
  const [preferenceId, setPreferenceId] = useState('');
  const [isCEPValid, setIsCEPValid] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [sedex, setSedex] = useState({
    value: 0,
    deliveryTime: 0
  });
  const [pac, setPac] = useState({
    value: 0,
    deliveryTime: 0
  });

  const [selectedShipping, setSelectedShipping] = useState(null);

  const [formData, setFormData] = useState({
    city: '',
    street: '',
    state: '',
    number: '',
    district: '',
    cep: '',
    complement: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    cpf: ''
  });

  useEffect(() => {
    const initializeMercadoPago = async () => {
    try {
    initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY, {
      locale: 'pt-BR', // The locale for the Mercado Pago form (optional)
    });
    }
    catch (error) {
      console.error('Error initializing Mercado Pago:', error);
    }

  }
  initializeMercadoPago();
  }, [])

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      formData.firstName = user.firstName;
      formData.lastName = user.lastName;
      formData.email = user.email;
      formData.phoneNumber = user.phoneNumber;
    }
  }, [formData]);

  useEffect(() => {
    if (isCEPValid) {
        CalculateShippingCost(formData.cep);
    }
}, [isCEPValid, formData.cep]);

  useEffect(() => {
    if(selectedShipping === 'SEDEX') {
      setShippingCost(sedex.value);
      setTotalAmount(subTotalAmount + shippingCost);
    } else if (selectedShipping === 'PAC') {
      setShippingCost(pac.value);
      setTotalAmount(subTotalAmount + shippingCost);
    }
  }, [subTotalAmount, shippingCost, sedex.value, pac.value, selectedShipping]);

  const fetchCartItems = async (cartData) => {
    try {
      const updatedCartItems = await Promise.all(
        cartData.map(async (cartItem) => {
          try {
            const itemResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}items/id=${cartItem.itemId}`);
            return { ...itemResponse.data, quantity: cartItem.quantity, cartId: cartItem.cartItemId };
          } catch (error) {
            console.error('Error fetching item details:', error);
            return cartItem;
          }
        })
      );
      setCartItems(updatedCartItems);
      setSubTotalAmount(updatedCartItems.reduce((acc, item) => acc + item.actualPrice * item.quantity, 0));
    } catch (error) {
      console.error('Error fetching cart items:', error);
    }
  };

  const loadCartItems = () => {
    const userString = sessionStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      setIsUserLoggedIn(true);
      setUserId(user.id);
      axios.get(`${process.env.REACT_APP_BACKEND_URL}carts/${user.id}`,{ headers: authHeader() })
        .then((response) => fetchCartItems(response.data))
        .catch((error) => console.error('Error fetching cart items:', error));
    } else {
      const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
      setCartItems(cart);
      setSubTotalAmount(cart.reduce((acc, item) => acc + item.actualPrice * item.quantity, 0));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(loadCartItems, []);

  const updateCartItems = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}carts/${userId}`,{ headers: authHeader() })
      .then((response) => fetchCartItems(response.data))
      .catch((error) => console.error('Error fetching cart items:', error));
  };

  const updateQuantity = (itemId, quantity) => {
    if (isUserLoggedIn) {
      axios.put(`${process.env.REACT_APP_BACKEND_URL}carts/${itemId}/q=${quantity}`,{},{ headers: authHeader() })
        .then(() => updateCartItems())
        .catch((error) => {
          toast.info(error.response.data.message);
          console.error('Error updating cart item:', error)});
    } else {
      const updatedCart = cartItems.map(item =>
        item.itemId === itemId ? { ...item, quantity: Math.max(1, quantity) } : item
      );
      setCartItems(updatedCart);
      sessionStorage.setItem('cart', JSON.stringify(updatedCart));
      setSubTotalAmount(updatedCart.reduce((acc, item) => acc + item.actualPrice * item.quantity, 0));
    }
  };

  const removeItem = (itemId) => {
    if (isUserLoggedIn) {
      axios.delete(`${process.env.REACT_APP_BACKEND_URL}carts/${itemId}`,{ headers: authHeader() })
        .then(() => {
          updateCartItems();
          toast.info('Item removed from cart.');
        })
        .catch((error) => console.error('Error deleting cart item:', error));
    } else {
      console.log('Removing item:', itemId);
      const updatedCart = cartItems.filter(item => item.itemId !== itemId);
      setCartItems(updatedCart);
      sessionStorage.setItem('cart', JSON.stringify(updatedCart));
      setSubTotalAmount(updatedCart.reduce((acc, item) => acc + item.actualPrice * item.quantity, 0));
      toast.info('Item removed from cart.');
    }
  };

  const handleNumberInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    
    setFormData({
      ...formData,
      number: value
    });
  };
  
  const handleCPFChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); 
    if (value.length > 3) {
      value = value.slice(0, 3) + '.' + value.slice(3);
    }
    if (value.length > 7) {
      value = value.slice(0, 7) + '.' + value.slice(7);
    }
    if (value.length > 11) {
      value = value.slice(0, 11) + '-' + value.slice(11);
    }
    setFormData({
      ...formData,
      cpf: value
    });

    
  };
  
  
  const handleCEPChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 5) {
      value = value.slice(0, 5) + '-' + value.slice(5);
    }
    if (value.length < 9) {
      setIsCEPValid(false);
    }

    setFormData({
      ...formData,
      cep: value
    });
  
    
    if (value.length === 9) {
      axios.get(`https://viacep.com.br/ws/${value}/json/`)
        .then((response) => {
          const { localidade, logradouro, uf, bairro, erro } = response.data;
  
        
          if (erro) {
            alert('CEP inválido. Por favor, digite um CEP válido.');
            setIsCEPValid(false);
            setFormData({
              ...formData,
              cep: value,
              city: '',
              street: '',
              state: '',
              number: '',
              district: ''
            });

            return;
          }
          else{
          setFormData({
            ...formData,
            cep: value,  
            city: localidade,
            street: logradouro,
            state: uf,
            district: bairro
          });
          setIsCEPValid(true);
        }
        })
        .catch((error) => {
          alert('CEP inválido. Por favor, digite um CEP válido.');
          setIsCEPValid(false);
        });
    }
  };

  const CalculateShippingCost = (cep) => {

    setIsLoading(true);

    // Remove any non-numeric characters from CEPs
    const originCep = process.env.REACT_APP_CEP_ORIGEM.replace(/\D/g, '');
    const destinationCep = cep.replace(/\D/g, '');

    let weight = 0;
    let height = 0;
    let width = 0;
    let length = 0;

    // Loop through cart items to calculate dimensions
    cartItems.forEach(item => {
        const { peso, altura, largura, comprimento } = extractItemDetails(item.description);
        weight += peso;
        height = Math.max(height, altura);
        width = Math.max(width, largura);
        length += comprimento;
    });

    // Ensure minimum dimensions are 13 cm
    height = Math.max(Math.round(height), 13);
    width = Math.max(Math.round(width), 13);
    length = Math.max(Math.round(length), 13);

  
        axios.get(`https://www.cepcerto.com/ws/json-frete/${originCep}/${destinationCep}/${weight}/${height}/${width}/${length}/${process.env.REACT_APP_CEP_CERTO_CHAVE}`)
        .then(response => {

            const { valorpac, prazopac, valorsedex, prazosedex } = response.data;

            setPac({
                value: parseFloat(valorpac.replace(',', '.')), 
                deliveryTime: parseInt(prazopac)
            });

            setSedex({
                value: parseFloat(valorsedex.replace(',', '.')), 
                deliveryTime: parseInt(prazosedex)
            });

            setIsLoading(false);
        })
        .catch(error => {
            console.error("Error calculating shipping cost:", error);
        });
};


  const extractItemDetails = (description) => {
    const alturaMatch = description.match(/Altura:\s*(\d+(\.\d+)?)\s*cm/i);
    const larguraMatch = description.match(/Largura:\s*(\d+(\.\d+)?)\s*cm/i);
    const comprimentoMatch = description.match(/Comprimento:\s*(\d+(\.\d+)?)\s*cm/i);
    const pesoMatch = description.match(/Peso:\s*(\d+(\.\d+)?)\s*g/i);

    return {
        altura: alturaMatch ? parseFloat(alturaMatch[1]) : 0,
        largura: larguraMatch ? parseFloat(larguraMatch[1]) : 0,
        comprimento: comprimentoMatch ? parseFloat(comprimentoMatch[1]) : 0,
        peso: pesoMatch ? parseFloat(pesoMatch[1]) : 0
    };
};

  const handleCheckout = () => {
    if (!isAgreed) {
      setShowError(true); // Show error if checkbox is not checked
      return;
    }
    setShowError(false); // Hide error if checkbox is checked
    setCheckout(true);
    setContactInfo(true);
  };


  const handleInfoSubmit = (e) => {
    e.preventDefault();
    if (!validateCPF(formData.cpf)) {
      alert('CPF inválido. Por favor, digite um CPF válido.');
      return;
    }
    if (formData.phoneNumber.replace(/\D/g, '').length !== 11) {
      alert('Número de telefone inválido. Por favor, digite um número de telefone válido.');
      return;
    }
    setShipping(true);
    setContactInfo(false);
  };

function validateCPF(cpf) {
  
  cpf = cpf.replace(/[^\d]/g, '');

  if (cpf.length !== 11) return false;


  if (/^(\d)\1+$/.test(cpf)) return false;


  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let firstCheckDigit = (sum * 10) % 11;
  if (firstCheckDigit === 10 || firstCheckDigit === 11) firstCheckDigit = 0;
  if (firstCheckDigit !== parseInt(cpf.charAt(9))) return false;


  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  let secondCheckDigit = (sum * 10) % 11;
  if (secondCheckDigit === 10 || secondCheckDigit === 11) secondCheckDigit = 0;
  if (secondCheckDigit !== parseInt(cpf.charAt(10))) return false;

  return true;
}


  const handleSubmit = (e) => {

    e.preventDefault();

    if (!selectedShipping) {
      setErrorMessage('Por favor, escolha um método de envio.');
      return;
    }

    // Reset error message
    setErrorMessage('');
   
    const cartIds = cartItems.map(item => item.cartId);

    const items = cartItems.map(item => {
      return {
        itemId: item.itemId,
        quantity: item.quantity
      };
    });
    console.log('Cart IDs:', cartIds);
    if (isUserLoggedIn) {
     
      setIsLoading(true);
      
      axios.post(`${process.env.REACT_APP_BACKEND_URL}orders/auth`, {
        cart_items_ids: cartIds,
        totalAmount: totalAmount,
        shippingCost: shippingCost,
        shippingMethod: selectedShipping,
        shippingAddress:{
          city: formData.city,
          street: formData.street,
          state: formData.state,
          number: formData.number,
          complement: formData.complement,
          district: formData.district,
          cep: formData.cep
        },
        customerInfo: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          cpf: formData.cpf
        }
      },{ headers: authHeader() })
      .then((res) => {
        toast.success('Pedido realizado com sucesso!');
        // setCartItems([]);
        // setTotalAmount(0);
        setPreferenceId(res.data.preferenceId);
        setPayment(true);
        setShipping(false);
      })
      .catch((error) => {
        console.error('Erro ao realizar o pedido:', error);
        toast.error('Erro ao realizar o pedido. Por favor, tente novamente mais tarde.');
      })
      .finally(() => {
        setIsLoading(false);
      });

    } else {

      setIsLoading(true);

      axios.post(`${process.env.REACT_APP_BACKEND_URL}orders`, {
        items: items,
        shippingCost: shippingCost,
        shippingMethod: selectedShipping,
        totalAmount: totalAmount,
        shippingAddress:{
          city: formData.city,
          street: formData.street,
          state: formData.state,
          number: formData.number,
          complement: formData.complement,
          district: formData.district,
          cep: formData.cep
        },
        customerInfo: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          cpf: formData.cpf
        }
      },{ headers: authHeader() })
      .then((res) => {
        toast.success('Pedido realizado com sucesso!');
        setCartItems([]);
        // setTotalAmount(0);
        setPreferenceId(res.data.preferenceId);
        setPayment(true);
        setShipping(false);
      }
      )
      .catch((error) => {
        console.error('Erro ao realizar o pedido:', error);
        toast.error('Erro ao realizar o pedido. Por favor, tente novamente mais tarde.');
      })
      .finally(() => {
        setIsLoading(false);
      });

    }
  }
  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      <Header />
  
      <ToastContainer position="bottom-right" autoClose={3000} />
  
      <div className="flex-grow mt-16  bg-[url('./background.png')] bg-cover  bg-center bg-no-repeat">
        {cartItems.length === 0 && !payment ? (
          <div className="min-h-screen flex justify-center items-center">
            <p className="text-gray-600 text-lg">Seu carrinho está vazio. <Link to="/">Continuar comprando</Link></p>
          </div>
        ) : (
          <div className="container mx-auto py-16 px-4 lg:px-8 flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-12">
            
            {!checkout &&(
              <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold mb-6">Carrinho de Compras</h1>
                {isUserLoggedIn ? 
                  <div>
                    {cartItems.map(item => (
                      <CartComponentItem key={item.itemId} item={item} updateQuantity={updateQuantity} removeItem={removeItem}/>
                    ))}
                  </div>
                :
                  <div>
                    {cartItems.map(item => (
                      <CartItemSession key={item.itemId} item={item} updateQuantity={updateQuantity} onDelete={removeItem} />
                    ))}
                  </div>
                }
              </div>
            )}
  
            {payment && (
              <div className="flex-1 bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center text-center">
                <FaCheckCircle className="text-green-500 text-6xl mb-6" />
                <h1 className="text-xl font-bold text-gray-800 mb-4">
                  Pague a sua compra com Mercado Pago clicando no botão azul!
                </h1>
              </div>
            )}
  
            {checkout && contactInfo && (
              <div className="flex-1 bg-white p-8 rounded-lg shadow-lg">
                <h1 className="text-3xl mb-8 text-center">Informações de Contato</h1>
  
                <form className="grid grid-cols-1 md:grid-cols-2 gap-6" onSubmit={handleInfoSubmit}>
                  <div>
                    <label htmlFor="firstName" className="block text-lg font-semibold text-gray-700">Nome</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                      placeholder="Nome"
                      required
                    />
                  </div>
  
                  <div>
                    <label htmlFor="lastName" className="block text-lg font-semibold text-gray-700">Sobrenome</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                      placeholder="Sobrenome"
                      required
                    />
                  </div>
  
                  <div>
                    <label htmlFor="email" className="block text-lg font-semibold text-gray-700">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                      placeholder="Digite seu email"
                      required
                    />
                  </div>
  
                  <div>
                    <label htmlFor="phoneNumber" className="block text-lg font-semibold text-gray-700">Número de Telefone</label>
                    <InputMask
                      mask="(99) 99999-9999"
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                      placeholder="(99) 99999-9999"
                      required
                    />
                  </div>
  
                  <div>
                    <label htmlFor="cpf" className="block text-lg font-semibold text-gray-700">CPF</label>
                    <input
                      type="text"
                      id="cpf"
                      name="cpf"
                      value={formData.cpf}
                      onChange={handleCPFChange}
                      maxLength={14}
                      className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                      placeholder="Digite seu CPF (XXX.XXX.XXX-XX)"
                      required
                    />
                  </div>
  
                  <div className="col-span-1 md:col-span-2 text-center">
                    <button
                      type="submit"
                      className="w-full bg-gradient-to-r from-blue-600 to-indigo-500 text-white px-6 py-3 rounded font-semibold shadow-md hover:shadow-lg transition-transform transform hover:scale-105 duration-300"
                    >
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            )}
  
            {checkout && shipping && (
              <div className="flex-1 bg-white p-8 rounded-lg shadow-lg">
                <h1 className="text-3xl mb-8 text-center">Endereço de Entrega</h1>
  
                <form className="grid grid-cols-1 md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="cep" className="block text-lg font-semibold text-gray-700">CEP*</label>
                    <input
                      type="text"
                      id="cep"
                      name="cep"
                      value={formData.cep}
                      onChange={handleCEPChange}
                      maxLength={9}
                      className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                      placeholder="Digite seu CEP (XXXXX-XXX)"
                      required
                    />
                  </div>

                  {isCEPValid && (
                    <>
                      <div>
                        <label htmlFor="city" className="block text-lg font-semibold text-gray-700">Cidade*</label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                          className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                          placeholder="Digite sua cidade"
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="street" className="block text-lg font-semibold text-gray-700">Rua*</label>
                        <input
                          type="text"
                          id="street"
                          name="street"
                          value={formData.street}
                          onChange={handleInputChange}
                          className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                          placeholder="Digite sua rua"
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="state" className="block text-lg font-semibold text-gray-700">Estado*</label>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                          className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                          placeholder="Digite seu estado"
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="district" className="block text-lg font-semibold text-gray-700">Bairro*</label>
                        <input
                          type="text"
                          id="district"
                          name="district"
                          value={formData.district}
                          onChange={handleInputChange}
                          className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                          placeholder="Digite seu bairro"
                          required
                        />
                      </div>

                      <div>
                        <label htmlFor="number" className="block text-lg font-semibold text-gray-700">Número*</label>
                        <input
                          type="text"
                          id="number"
                          name="number"
                          value={formData.number}
                          onChange={handleNumberInputChange}
                          className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                          placeholder="Digite o número da casa"
                          required
                        />
                      </div>

                      {/* Flex Container for Complemento and Shipping Option Selection */}
                      <div className="col-span-1 md:col-span-2 flex flex-col md:flex-row justify-between items-start gap-6">
                        <div className="flex-1">
                          <label htmlFor="complement" className="block text-lg font-semibold text-gray-700">Complemento</label>
                          <input
                            type="text"
                            id="complement"
                            name="complement"
                            value={formData.complement}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                            placeholder="Informações adicionais"
                          />
                        </div>

                        {/* Shipping Option Selection */}
                        <div className="flex-1">
                          <label className="block text-lg font-semibold text-gray-700">Escolha o Método de Envio:</label>
                          <div className="flex flex-col">
                            <label className="flex items-center mt-2">
                              <input
                                type="radio"
                                value="SEDEX"
                                checked={selectedShipping === 'SEDEX'}
                                onChange={(e) => setSelectedShipping(e.target.value)}
                                className="mr-2"
                              />
                              SEDEX - {sedex.value > 0 ? <span>R$ {sedex.value.toFixed(2)} (Prazo: {sedex.deliveryTime} dias)</span> : <span>Calculando...</span>}
                            </label>
                            <label className="flex items-center mt-2">
                              <input
                                type="radio"
                                value="PAC"
                                checked={selectedShipping === 'PAC'}
                                onChange={(e) => setSelectedShipping(e.target.value)}
                                className="mr-2"
                              />
                              PAC - {pac.value > 0 ? <span>R$ {pac.value.toFixed(2)} (Prazo: {pac.deliveryTime} dias)</span> : <span>Calculando...</span>}
                            </label>
                          </div>
                          {errorMessage && <p className="mt-2 text-red-500">{errorMessage}</p>}
                        </div>
                      </div>

                      <div className="col-span-1 md:col-span-2 text-center">
                      <button
                        type="submit"
                        className={`w-full bg-gradient-to-r from-blue-600 to-indigo-500 text-white px-6 py-3 rounded font-semibold shadow-md hover:shadow-lg transition-transform transform hover:scale-105 duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Enviando...' : 'Enviar Detalhes do Frete'}
                      </button>
                      </div>
                    </>
                  )}
                </form>

              </div>
            )}
  
            <div className="w-full lg:w-1/3 bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-6">Resumo do Pedido</h2>
              <div className="flex justify-between mb-4">
                <span className="text-gray-600">Subtotal</span>
                <span className="text-gray-900">R${subTotalAmount.toFixed(2)}</span>
              </div>
              <div className="flex justify-between mb-4">
                <span className="text-gray-600">Frete</span>
                <span className="text-gray-900">{shippingCost == 0 ? <span>Calculando...</span>: <span>R${shippingCost.toFixed(2)}</span> }</span>
              </div>
              <div className="flex justify-between text-lg font-bold">
                <span>Total</span>
                <span>{totalAmount > 0 ? <span>R${totalAmount.toFixed(2)}</span> : <span>Calculando...</span>}</span>
              </div>
  
              {!checkout && (
                <form>
                  <div className="mt-4">
                    <input
                      type="checkbox"
                      id="agreeCheckbox"
                      checked={isAgreed}
                      onChange={(e) => {
                        setIsAgreed(e.target.checked);
                        setShowError(false); // Hide error when checkbox is checked
                      }}
                      required
                      className="mr-2"
                    />
                    <span className="text-red-700"> * </span>
                    <label htmlFor="agreeCheckbox" className="text-gray-700">
                      Eu concordo com os{" "}
                      <a
                        href="/policies#terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Termos de Serviço
                      </a>
                      ,{" "}
                      <a
                        href="/policies#privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Política de Privacidade
                      </a>
                      , e{" "}
                      <a
                        href="/policies#returns"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Política de Trocas e Devoluções
                      </a>
                    </label>
                  </div>
                  
                  {showError && (
                    <p className="text-red-600 mt-2">
                      Você deve concordar com as políticas para continuar.
                    </p>
                  )}
                  
                  <button
                    type="button"
                    className={`w-full py-3 px-4 rounded-lg font-semibold hover:bg-gray-800 bg-black text-white mt-6 transition cursor-pointer `}
                    onClick={handleCheckout}
                  >
                    Continuar para o Checkout
                  </button>
                </form>
              )}
              {checkout && payment && (
                <Wallet
                  initialization={{ preferenceId: preferenceId }}
                  customization={{
                    texts: { valueProp: 'Opção inteligente' }, // Customized text for the button
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
  
};

export default CartPage;
